import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreMenuService {

  private storeMenuActive = new BehaviorSubject<boolean>(false)

  storeMenuActive$ = this.storeMenuActive.asObservable()

  constructor() { }

  isActive(value: boolean) {
    this.storeMenuActive.next(value)
  }
}
