import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from './../../authentication/services/authentication.service';
import { CartItem, OrderStoreData } from '../models/models';
import { BehaviorSubject, forkJoin, map, Observable, zip } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CollectionWrapper, MessageResponse } from '../../shared/models/models';
import { ToastService } from '../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastSeverity } from '../../shared/models/enums';
import { CustomersService } from './customers.service';
import { ProductDetails } from '../../products/models/models';

@Injectable({
  providedIn: 'root',
})
export class CartService {

  private cartItems = new BehaviorSubject<CartItem[]>([])

  cartItems$ = this.cartItems.asObservable()

  private readonly STORAGE_KEY = 'CARTITEMS'

  constructor(private httpClient: HttpClient, private customersService: CustomersService, private authenticationService: AuthenticationService, private toastService: ToastService, private translateService: TranslateService) {
    this.getCartItems()
  }

  private get notAuthenticated() {
    return !this.authenticationService.currentUser
  }

  private refreshStoresItems(storedItems: CartItem[]) {
    const productsObservables: Observable<ProductDetails>[] = []

    const storesObservables: Observable<OrderStoreData>[] = []

    storedItems.forEach((item, index) => {
      productsObservables.push(this.customersService.getProductDetails(item.store.slug, item.product.slug))

      storesObservables.push(this.customersService.getStore(item.store.slug))
    })

    // Combine each product with its corresponding store
    const combinedObservables = storedItems.map((item, index) =>
      zip(productsObservables[index], storesObservables[index]).pipe(
        map(([product, store]) => ({
          id: 0,
          product,
          store
        }))
      )
    );

    // Use forkJoin to wait for all combined observables to complete
    return forkJoin(combinedObservables);
  }

  private getCartItems() {
    const storedItemsString = localStorage.getItem(this.STORAGE_KEY)

    const storedItems = storedItemsString ? JSON.parse(storedItemsString) as CartItem[] : []

    if (storedItems.length !== 0) {
      this.refreshStoresItems(storedItems).subscribe(value => {
        this.cartItems.next(value.sort((item1, item2) => item1.product.sortIndex - item2.product.sortIndex))
      })

      return
    }

    if (this.notAuthenticated) {
      return
    }

    const url = environment.url + `customers/cart`

    this.httpClient.get<CollectionWrapper<CartItem>>(url).subscribe(value => {
      this.cartItems.next(value.results)

      this.persist()
    })
  }

  persist() {
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(this.cartItems.value))
  }

  addCartItem(cartItem: CartItem) {
    if (this.notAuthenticated) {
      this.cartItems.next([...this.cartItems.value, cartItem])

      this.persist()

      this.translateService.get('product.addedToCart').subscribe(value => this.toastService.addMessage({ message: value, severity: ToastSeverity.Success }))

      return
    }

    const url = environment.url + `customers/cart`

    this.httpClient.post<MessageResponse>(url, { productId: cartItem.product.id }).subscribe(value => {
      this.getCartItems()

      this.translateService.get('product.addedToCart').subscribe(value => this.toastService.addMessage({ message: value, severity: ToastSeverity.Success }))
    })
  }

  deleteCartItem(index: number) {
    const cartItem = this.cartItems.value[index]

    if (!cartItem) {
      return
    }

    const newItems = this.cartItems.value.filter((item, itemIndex) => itemIndex !== index)

    this.cartItems.next(newItems)

    this.persist()

    if (this.notAuthenticated) {
      this.translateService.get('product.removedFromCart').subscribe(value => this.toastService.addMessage({ message: value, severity: ToastSeverity.Success }))

      return
    }

    const url = environment.url + `customers/cart/${cartItem.id}`

    this.httpClient.delete<MessageResponse>(url).subscribe(value => {
      this.getCartItems()

      this.translateService.get('product.removedFromCart').subscribe(value => this.toastService.addMessage({ message: value, severity: ToastSeverity.Success }))
    })
  }

  checkoutItems(cartItems: CartItem[]) {
    const filteredItems = this.cartItems.value.filter(cartItem => cartItems.filter(item => item.product.id == cartItem.product.id).length === 0)

    this.cartItems.next([...filteredItems])

    this.persist()
  }
}
