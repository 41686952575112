import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GetStoreReviewsRequest, HomePageStatistics, MenuDto, MenuRequest, Product, Store } from '../models/models';
import { CollectionWrapper, GeneralStore, GetStoresRequest, PaginatedResult, PaginationParameters } from '../../shared/models/models';
import { getHttpParams } from '../../utilities/params-utilities';
import { Category } from '../../categories/models/models';
import { StoreDetails } from '../models/models';
import { GetProductsRequest, ProductDetails } from '../../products/models/models';
import { StoreReview } from './../models/models';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(private httpClient: HttpClient) { }

  getStatistics() {
    const url = environment.url + 'customers/statistics'

    return this.httpClient.get<HomePageStatistics>(url)
  }

  getStores(request: GetStoresRequest) {
    const url = environment.url + 'customers/stores'

    const params = getHttpParams(request)

    return this.httpClient.get<PaginatedResult<Store>>(url, { params: params })
  }

  getStore(slug: string) {
    const url = environment.url + `customers/stores/${slug}`

    return this.httpClient.get<StoreDetails>(url)
  }

  getStoreCategories(storeId: number, request: { pageIndex: number, pageCount: number, ascending: boolean, searchTerm?: string }) {
    const url = environment.url + `stores/${storeId}/categories`

    return this.httpClient.get<PaginatedResult<Category>>(url, { params: getHttpParams(request) })
  }

  getStoreReviews(storeId: number, request: GetStoreReviewsRequest) {
    const url = environment.url + `customers/stores/${storeId}/reviews`

    return this.httpClient.get<PaginatedResult<StoreReview>>(url, { params: getHttpParams(request) })
  }

  getStoreProducts(request: MenuRequest, storeId: number) {
    const url = environment.url + `stores/${storeId}/products`

    const params = getHttpParams(request)

    return this.httpClient.get<PaginatedResult<Product>>(url, { params: params })
  }

  getStoreQrCodeUrl(slug: string) {
    return environment.url + `customers/stores/${slug}/menu`
  }

  getProductDetails(storeSlug: string, slug: string) {
    const url = environment.url + `stores/${storeSlug}/products/${slug}`

    return this.httpClient.get<ProductDetails>(url)
  }

  getStoreBranches(storeId: number, paginationParameters: PaginationParameters) {
    const url = environment.url + `customers/stores/${storeId}/branches`

    const params = getHttpParams(paginationParameters)

    return this.httpClient.get<PaginatedResult<GeneralStore>>(url, { params: params })
  }
}
