<div class="wrapper wrapper-full_width">
  <!-- Side Menu Start -->
  <header id="header">
    <div class="main-header">
      <div class="container">
        <div class="row">
          <div class="">
            <div
              class="main-location d-flex flex-col md:flex-row justify-around"
            >
              <div class="ImageElement">
                <a (click)="closeMenu()">
                  <img
                    src="assets/media/foodenza/logo-3.png"
                    class="img-fluid h-100px object-cover mx-auto"
                  />
                </a>
                <li class="d-flex flex-row align-center justify-center d-md-none fw-bold text-2xl">
                  <span
                    *ngIf="cartItems.length !== 0"
                    class="mt-2 inline-flex items-center justify-center w-6 h-6 text-sm font-semibold text-red-800 bg-red-100 rounded-full"
                  >
                    {{ cartItems.length }}
                  </span>
                  <a routerLink="{{ '/customer/cart' }}" (click)="closeMenu()" routerLinkActive="active"
                    ><i class="icon-cart2"></i>{{ "home.cart" | translate }}</a
                  >
                </li>
                <div  class="mobileView">
                  <div class="lines" [ngClass]="{'activeLine': menuActiveMobile}">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                  </div>
      
                </div>
              </div>
              <ul
                class="ListLinkNav d-flex flex-col md:flex-row justify-start md:justify-center self-center mx-8"
                style="width: 70%;"
              >
                <li *ngIf="isLoggedIn && isStoreOwner" [ngClass]="{'activeListMobileView': !menuActiveMobile}">
                  <a target="_blank" routerLink="{{ '/dashboard' }}" (click)="closeMenu()" routerLinkActive="active"
                    ><i class="icon-dashboard3"></i
                    >{{ "shared.dashboard" | translate }}</a
                  >
                </li>
                <li *ngIf="!menuActive" [ngClass]="{'activeListMobileView': !menuActiveMobile}">
                  <a routerLink="{{ '/stores' }}" (click)="closeMenu()" routerLinkActive="active">
                    <i class="icon-restaurant_menu"> </i
                    >{{ "home.restuarants" | translate }}
                  </a>
                </li>
                <li class="d-md-flex flex-row align-center justify-center d-none" [ngClass]="{'activeListMobileView': !menuActiveMobile}">
                  <span
                    *ngIf="cartItems.length !== 0"
                    class="mt-2 inline-flex items-center justify-center w-6 h-6 text-sm font-semibold text-red-800 bg-red-100 rounded-full"
                  >
                    {{ cartItems.length }}
                  </span>
                  <a routerLink="{{ '/customer/cart' }}" (click)="closeMenu()" routerLinkActive="active"
                    ><i class="icon-cart2"></i>{{ "home.cart" | translate }}</a
                  >
                </li>
                <li *ngIf="!isLoggedIn" [ngClass]="{'activeListMobileView': !menuActiveMobile}">
                  <a routerLink="{{ '/account/register' }}" (click)="closeMenu()" routerLinkActive="active"
                    ><i class="icon-account_box"></i
                    >{{ "home.register" | translate }}</a
                  >
                </li>
                <li  [ngClass]="{'activeListMobileView': !menuActiveMobile}">
                    <a routerLink="{{'/customer/orderhistory'}}" (click)="closeMenu()" routerLinkActive="active"
                        ><i class="icon-history"></i>{{ "home.orderHistory" | translate }}</a
                    >
                </li>
                <li *ngIf="isLoggedIn" [ngClass]="{'activeListMobileView': !menuActiveMobile}">
                  <a class="notification"  style="padding-top: 0;padding-bottom: 20px; " routerLinkActive="active">
                    <app-notification-dropdown  />
                  </a>
                </li>
                <li  *ngIf="!menuActive" [ngClass]="{'activeListMobileView': !menuActiveMobile}">

                  <a>
                    <app-language-dropdown />
                  </a>
                  
                </li>
                <li [ngClass]="{'activeListMobileView': !menuActiveMobile}"
                  *ngIf="isLoggedIn"
                  class="hover:cursor-pointer"
                  (click)="logout()"
                >
                  <a><i class="icon-log-out"></i></a>
                </li>
              </ul>
              <div [ngClass]="{'activeListMobileView': !menuActiveMobile}" class="LoginContainer self-center md:self-start" *ngIf="!isLoggedIn">
                <div class="login-option cursor-pointer text-center" style="text-wrap: nowrap;">
                  <a
                    class="get-start-btn"
                    *ngIf="!isLoggedIn"
                    routerLink="{{ '/account/login' }}"
                  >
                    {{ "login.login" | translate }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="FakeHeader"></div>

  <div class="body-bg p-4" style="padding: 0 !important;">
    <router-outlet></router-outlet>
  </div>

  <footer id="footer" class="footer-style-2">
    <div class="footer-widget" *ngIf="!menuActive">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="widget widget-connect">
                  <div class="widget-title">
                    <h5>{{ 'home.contactUs' | translate }}</h5>
                  </div>
                  <ul>
                    <li>
                      <span class="bgcolor"
                        ><i class="icon-ring_volume"></i>
                      </span>
                      <p>0233023238</p>
                    </li>
                    <li>
                      <span class="bgcolor"
                        ><i class="icon-envelope-o"></i>
                      </span>
                      <p>
                        <a href="mailto:info@foodenza.com

                        "
                          > {{ 'info@foodenza.com' }}

                          </a
                        >
                      </p>
                    </li>
                    <li>
                      <span class="bgcolor"
                        ><i class="icon-location-pin2"></i>
                      </span>
                      <p>
                        Zhraa El Maadi ,Nokta Street
                      </p>
                    </li>
                  </ul>
                  <ul class="d-flex flex-row justify-content-center">
                    <li>
                      <a href="https://wa.link/now557" target="_blank">
                        <span class="bgcolor"
                        ><i class="icon-whatsapp"></i>
                      </span>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/foodenza1/" target="_blank">
                      <span class="bgcolor"
                        ><i class="icon-facebook"></i>
                      </span>
                    </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- /.cs-footer-widgets -->
    <div class="copyright-sec">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="copyright-inner">
              <div class="copy-right">
                <p>
                  © {{ 'shared.footerCopyrights'  | translate }} <span style="color:#c33332">Foodenza</span>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.cs-copyright -->
  </footer>
  <!-- /#footer -->
</div>
<!-- /.wrapper -->
