import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { CustomersService } from '../../services/customers.service';
import { CartItem, HomePageStatistics } from '../../models/models';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../authentication/services/authentication.service';
import { StoreMenuService } from '../../services/store-menu.service';
import { SettingsService } from './../../../shared/services/settings.service';
import { Language } from '../../../shared/models/enums';
import { CartService } from './../../services/cart.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent implements OnInit, OnDestroy {
  bodyClasses = [
    'home',
    'page-template-default',
    'page',
    'page-id-15181',
    'theme-foodbakery',
    'woocommerce-no-js',
    'wp-foodbakery'
  ]

  children: any[] = []

  statistics?: HomePageStatistics

  subscriptions: Subscription[] = []

  isLoggedIn: boolean = false

  menuActive: boolean = false

  cartItems: CartItem[] = []

  isStoreOwner: boolean = false

  constructor(private cartService: CartService, 
    private settingsService: SettingsService, 
    private cdr: ChangeDetectorRef, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document, private customersService: CustomersService, private authService: AuthenticationService, public storeMenuService: StoreMenuService) {
    if (authService.currentUser) {
      this.isLoggedIn = true

      this.isStoreOwner = authService.isStoreOwner()
    }
  }

  ngOnDestroy(): void {
    this.children.forEach(child => {
      this.renderer.removeChild(this.document.head, child)
    })

    this.bodyClasses.forEach(bodyClass => {
      this.renderer.removeClass(this.document.body, bodyClass)
    })

    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit(): void {
    const iconMoonCss = this.renderer.createElement('link')
    this.renderer.setAttribute(iconMoonCss, 'rel', 'stylesheet')
    this.renderer.setAttribute(iconMoonCss, 'href', 'assets/iconmoon3781.css')
    this.children.push(iconMoonCss)

    // const bootstrapCss = this.renderer.createElement('link')
    // this.renderer.setAttribute(bootstrapCss, 'rel', 'stylesheet')
    // this.renderer.setAttribute(bootstrapCss, 'href', 'assets/bootstrap3781.css')
    // this.children.push(bootstrapCss)

    // const bootstrapThemeCss = this.renderer.createElement('link')
    // this.renderer.setAttribute(bootstrapThemeCss, 'rel', 'stylesheet')
    // this.renderer.setAttribute(bootstrapThemeCss, 'href', 'assets/bootstrap-theme3781.css')
    // this.children.push(bootstrapThemeCss)

    const styleCss = this.renderer.createElement('link')
    this.renderer.setAttribute(styleCss, 'rel', 'stylesheet')
    this.renderer.setAttribute(styleCss, 'href', 'assets/style3781.css')
    this.children.push(styleCss)

    const widgetCss = this.renderer.createElement('link')
    this.renderer.setAttribute(widgetCss, 'rel', 'stylesheet')
    this.renderer.setAttribute(widgetCss, 'href', 'assets/widget3781.css')
    this.children.push(widgetCss)

    const foodbakryPluginCss = this.renderer.createElement('link')
    this.renderer.setAttribute(foodbakryPluginCss, 'rel', 'stylesheet')
    this.renderer.setAttribute(foodbakryPluginCss, 'href', 'assets/cs-foodbakery-plugin3781.css')
    this.children.push(foodbakryPluginCss)

    const responsiveCss = this.renderer.createElement('link')
    this.renderer.setAttribute(responsiveCss, 'rel', 'stylesheet')
    this.renderer.setAttribute(responsiveCss, 'href', 'assets/responsivee735.css')
    this.children.push(responsiveCss)

    const defaultElementCss = this.renderer.createElement('link')
    this.renderer.setAttribute(defaultElementCss, 'rel', 'stylesheet')
    this.renderer.setAttribute(defaultElementCss, 'href', 'assets/default-element74c7.css')
    this.children.push(defaultElementCss)

    const styleMinCss = this.renderer.createElement('link')
    this.renderer.setAttribute(styleMinCss, 'rel', 'stylesheet')
    this.renderer.setAttribute(styleMinCss, 'href', 'assets/style.min3781.css')
    this.children.push(styleMinCss)

    const rsStyle = this.renderer.createElement('link')
    this.renderer.setAttribute(rsStyle, 'rel', 'stylesheet')
    this.renderer.setAttribute(rsStyle, 'href', 'assets/rs6627e.css')
    this.children.push(rsStyle)

    this.children.forEach(child => this.renderer.appendChild(this.document.head, child))

    this.bodyClasses.forEach(bodyClass => {
      this.renderer.addClass(this.document.body, bodyClass)
    })

    const rtl = this.renderer.createElement('link')
    this.renderer.setAttribute(rtl, 'rel', 'stylesheet')
    this.renderer.setAttribute(rtl, 'href', 'assets/rtl.css')

    this.subscriptions.push(this.settingsService.language$.subscribe(value => {
      if (value === Language.AR) {
        this.renderer.appendChild(this.document.head, rtl)

        this.renderer.addClass(this.document.body, 'rtl')

        return
      }

      this.renderer.removeChild(this.document.head, rtl)

      this.renderer.removeClass(this.document.body, 'rtl')
    }))

    this.subscriptions.push(this.customersService.getStatistics().subscribe(value => this.statistics = value))

    this.subscriptions.push(this.storeMenuService.storeMenuActive$.subscribe(value => {
      this.menuActive = value

      this.cdr.detectChanges()
    }))

    this.subscriptions.push(this.cartService.cartItems$.subscribe(value => this.cartItems = value))
  }

  logout() {
    this.authService.logout()
  }

  @HostListener('click', ['$event.target'])
  onClick(target: HTMLElement) {
    if (target.classList.contains('lines')||target.classList.contains('line')) {
      this.toggleMenu();
    }else if(target.classList.contains('')){
      
    }
    
  }

  menuActiveMobile = false

  toggleMenu() {
    this.menuActiveMobile = !this.menuActiveMobile
  }

  closeMenu() {
    if (!this.menuActiveMobile) {
      return 
    }

    this.menuActiveMobile = false
  }
}
